import { render, staticRenderFns } from "./FormBox.vue?vue&type=template&id=5abaad54&scoped=true"
import script from "./FormBox.vue?vue&type=script&lang=js"
export * from "./FormBox.vue?vue&type=script&lang=js"
import style0 from "./FormBox.vue?vue&type=style&index=0&id=5abaad54&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5abaad54",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\工作\\项目\\智慧安全-2024\\sinoma-hse-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5abaad54')) {
      api.createRecord('5abaad54', component.options)
    } else {
      api.reload('5abaad54', component.options)
    }
    module.hot.accept("./FormBox.vue?vue&type=template&id=5abaad54&scoped=true", function () {
      api.rerender('5abaad54', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/workFlow/components/FormBox.vue"
export default component.exports