<template>
  <el-dialog v-bind="$attrs" :close-on-click-modal="false" :modal-append-to-body="false"
    v-on="$listeners" @open="onOpen" fullscreen lock-scroll class="SNOWS-full-dialog"
    :show-close="false" :modal="false" append-to-body>
    <div class="SNOWS-full-dialog-header">
      <div class="header-title">
        <img src="@/assets/images/default_avatar.png" class="header-logo" />
        <p class="header-txt"> · 打印预览</p>
      </div>
      <div class="options">
        <el-button type="primary" size="small" @click="word">下 载</el-button>
        <el-button type="primary" size="small" @click="print">打 印</el-button>
        <el-button @click="closeDialog()">取消</el-button>
      </div>
    </div>
    <div class="main" v-loading="loading">
      <div ref="tsPrint" class="print-content" v-html="printTemplate" />
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import { getData } from '@/api/system/printDev'
import QRCode from 'qrcodejs2'
import JsBarcode from 'jsbarcode'
export default {
  props: ['id', 'formId', 'fullName'],
  computed: {
    ...mapGetters(['userInfo'])
  },
  data() {
    return {
      data: {},
      printTemplate: '',
      recordList: [],
      loading: false
    }
  },
  methods: {
    onOpen() {
      if (!this.id) return
      this.printTemplate = ''
      this.data = {}
      this.loading = true
      let query = {
        id: this.id,
        formId: this.formId
      }
      getData(query).then(res => {
        if (!res.data) return
        this.printTemplate = res.data.printTemplate
        this.data = res.data.printData
        this.recordList = res.data.operatorRecordList || []
        this.$nextTick(() => {
          const tableList = this.$refs.tsPrint.getElementsByTagName('table')
          if (tableList.length) {
            for (let j = 0; j < tableList.length; j++) {
              const tableObj = tableList[j];
              let tds = []
              let newTable = []
              for (let i = 0; i < tableObj.rows.length; i++) {
                tds = tableObj.rows[i]
                const dataTag = this.isChildTable(tds.cells)
                if (dataTag) {
                  this.retrieveData(dataTag, tableObj, tds, newTable)
                } else {
                  newTable.push(tds)
                }
              }
              tableObj.getElementsByTagName('tbody')[0].innerHTML = ''
              for (let i = 0; i < newTable.length; i++) {
                tableObj.getElementsByTagName('tbody')[0].appendChild(newTable[i])
              }
            }
          }
        })
        this.replaceValue(this.data)
        this.replaceSysValue()
        this.replaceImg()
        this.replaceBarCode()
        this.replaceQrCode()
        this.loading = false
      })
    },
    isChildTable(cells) {
      let tableName = ''
      outer: for (let j = 0; j < cells.length; j++) {
        const cell = cells[j];
        let spanList = cells[j].getElementsByTagName('span')
        if (!spanList.length) break outer;
        let hasChildTable = false
        inner: for (let j = 0; j < spanList.length; j++) {
          const spanEle = spanList[j];
          const dataTag = spanEle.getAttribute('data-tag') ? spanEle.getAttribute('data-tag').split('.')[0] : 'null'
          if (dataTag && dataTag !== 'headTable' && dataTag !== 'null') {
            hasChildTable = true
            tableName = dataTag
            break inner
          }
        }
        if (hasChildTable) break outer;
      }
      return tableName
    },
    closeDialog() {
      this.$emit('update:visible', false)
    },
    generateTable(data, tds) {
      for (let key in data) {
        for (let j = 0; j < tds.cells.length; j++) {
          let spanList = tds.cells[j].getElementsByTagName('span')
          for (let i = 0; i < spanList.length; i++) {
            const dataTag = spanList[i].getAttribute('data-tag') ? spanList[i].getAttribute('data-tag').split('.')[1] : 'null'
            if (key == dataTag) {
              spanList[i].innerHTML = data[key]
            }
          }
        }
      }
      return tds
    },
    retrieveData(dataTag, tableObj, tds, newTable) {
      for (let key in this.data) {
        if (key == dataTag) {
          for (let j = 0; j < this.data[key].length; j++) {
            let tr = this.generateTable(this.data[key][j], tds.cloneNode(true))
            let tds1 = tr.children
            for (let i = 0; i < tds1.length; i++) {
              const element = tds1[i];
              this.replaceImg(element)
              this.replaceBarCode(element)
              this.replaceQrCode(element)
            }
            newTable.push(tr)
          }
        }
      }
    },
    getHandleName(handleStatus) {
      if (handleStatus == 0) return "退回"
      if (handleStatus == 1) return "通过"
      if (handleStatus == 2) return "发起"
      if (handleStatus == 3) return "撤回"
      if (handleStatus == 4) return "终止"
      if (handleStatus == 5) return "指派"
      if (handleStatus == 6) return "加签"
      if (handleStatus == 7) return "转审"
      if (handleStatus == 8) return "变更"
      if (handleStatus == 9) return "复活"
      return ''
    },
    replaceSysValue() {
      const recordList = this.recordList
      const systemPrinter = this.userInfo.userName + '/' + this.userInfo.userAccount
      const systemPrintTime = this.sinoma.toDate(new Date())
      let systemApprovalContent = ''
      if (recordList.length) {
        systemApprovalContent += '<table style="border-collapse: collapse; width: 100%;" border="1" data-mce-style="border-collapse: collapse; width: 100%;"><tbody><tr><td style="width:20%;" data-mce-style="width: 20%;">节点名称</td><td style="width:20%;" data-mce-style="width: 20%;">操作人员</td><td style="width:20%;" data-mce-style="width: 20%;">操作时间</td><td style="width:20%;" data-mce-style="width: 20%;">执行动作</td><td style="width: 20%;" data-mce-style="width: 20%;">备注</td></tr>'
        let content = ''
        for (let i = 0; i < recordList.length; i++) {
          const record = recordList[i];
          content += `<tr><td style="width: 20%;" data-mce-style="width: 20%;"><span class="wk-print-tag-wukong wk-tiny-color--common" contenteditable="false">${record.nodeName}</span></td><td style="width: 20%;" data-mce-style="width: 20%;"><span class="wk-print-tag-wukong wk-tiny-color--common" contenteditable="false">${record.userName}</span></td><td style="width: 20%;" data-mce-style="width: 20%;"><span class="wk-print-tag-wukong wk-tiny-color--common" contenteditable="false">${this.sinoma.toDate(record.handleTime)}</span></td><td style="width: 20%;" data-mce-style="width: 20%;"><span class="wk-print-tag-wukong wk-tiny-color--common" contenteditable="false">${this.getHandleName(record.handleStatus)}</span></td><td style="width: 20%;" data-mce-style="width: 20%;"><span class="wk-print-tag-wukong wk-tiny-color--common" contenteditable="false">${record.handleOpinion || ""}</span></td></tr>`
        }
        systemApprovalContent += content
        systemApprovalContent += '</tbody></table>'
      }
      this.printTemplate = this.replaceAll(this.printTemplate, '{systemPrinter}', systemPrinter)
      this.printTemplate = this.replaceAll(this.printTemplate, '{systemPrintTime}', systemPrintTime)
      this.printTemplate = this.replaceAll(this.printTemplate, '{systemApprovalContent}', systemApprovalContent)
    },
    replaceValue(data) {
      for (let key in data) {
        this.printTemplate = this.replaceAll(this.printTemplate, `{${key}}`, data[key] || '')
        if (Array.isArray(data[key]) && data[key] && data[key].length) {
          this.replaceValue(data[key])
        }
      }
    },
    replaceImg(childItem) {
      let imgRegular = /&lt;img(\S|\s)*?&lt;\/img&gt;/g
      let imgList = []
      if (childItem) {
        const element = childItem.innerHTML
        imgList = element.match(imgRegular)
      } else {
        imgList = this.printTemplate.match(imgRegular)
      }
      if (imgList && imgList.length) {
        for (var i = 0; i < imgList.length; i++) {
          const item = imgList[i]
          if (this.getIsChildren(item) && !childItem) continue
          const width = this.getWidthHeight(item)
          const height = this.getWidthHeight(item, 'height')
          let value = this.getValue(item)
          const replaceImg = (template) => {
            if (childItem) {
              childItem.innerHTML = template
            } else {
              this.printTemplate = this.replaceAll(this.printTemplate, item, template)
            }
          }
          const isArray = false
          try {
            isArray = Array.isArray(JSON.parse(value))
          } catch (error) {
            isArray = false
          }
          if (isArray) {
            const list = JSON.parse(value)
            let template = ''
            for (let index = 0; index < list.length; index++) {
              const element = list[index];
              if (element.url) {
                value = new RegExp('http').test(element.url) ? value : this.define.comUrl + element.url
                template += `<img width='${width}' height='${height}' src='${value}'/>`
              }
            }
            replaceImg(template)
          } else {
            value = new RegExp('http').test(value) ? value : this.define.comUrl + value
            let template = `<img width='${width}' height='${height}' src='${value}'/>`
            replaceImg(template)
          }
        }
      }
    },
    replaceBarCode(childItem) {
      let imgRegular = /&lt;barCode(\S|\s)*?&lt;\/barCode&gt;/g
      let imgList = []
      if (childItem) {
        const element = childItem.innerHTML
        imgList = element.match(imgRegular)
      } else {
        imgList = this.printTemplate.match(imgRegular)
      }
      if (imgList && imgList.length) {
        for (var i = 0; i < imgList.length; i++) {
          const item = imgList[i]
          if (this.getIsChildren(item) && !childItem) continue
          const width = this.getWidthHeight(item)
          const height = this.getWidthHeight(item, 'height')
          const value = this.getValue(item)
          const id = this.sinoma.idGenerator()
          const template = `<img width='${width}' height='${height}'  id='barcode${id}'/>`
          if (childItem) {
            childItem.innerHTML = template
          } else {
            this.printTemplate = this.replaceAll(this.printTemplate, item, template)
          }
          this.$nextTick(() => {
            this.getJsBarcode(value, '#barcode' + id, width, height)
          })
        }
      }
    },
    replaceQrCode(childItem) {
      let imgRegular = /&lt;qrCode(\S|\s)*?&lt;\/qrCode&gt;/g
      let imgList = []
      if (childItem) {
        const element = childItem.innerHTML
        imgList = element.match(imgRegular)
      } else {
        imgList = this.printTemplate.match(imgRegular)
      }
      if (imgList && imgList.length) {
        for (var i = 0; i < imgList.length; i++) {
          const item = imgList[i]
          if (this.getIsChildren(item) && !childItem) continue
          const width = this.getWidthHeight(item)
          const height = this.getWidthHeight(item, 'height')
          const value = this.getValue(item)
          const id = this.sinoma.idGenerator()
          const template = `<span id='qrCode${id}'/>`
          if (childItem) {
            childItem.innerHTML = template
          } else {
            this.printTemplate = this.replaceAll(this.printTemplate, item, template)
          }
          this.$nextTick(() => {
            this.getJsQrcode(value, 'qrCode' + id, width, height)
          })
        }

      }
    },

    getWidthHeight(item, type = 'width') {
      let regular = ""
      if (type == 'width') regular = /width=[\"|'](.*?)[\"|']/gi;
      if (type == 'height') regular = /height=[\"|'](.*?)[\"|']/gi;
      let quotes = /["|'](.*)["|']/;
      let data = item.match(regular)
      let value = ""
      if (data && data.length) {
        let res = data[0].match(quotes)
        value = res && res.length ? res[1] : 100
      }
      return value
    },
    getValue(item) {
      let regexp = /(?<=(((<|&lt;)[a-zA-Z-]+?){0,1}(>|&gt;)))([\s\S]+)(?=([\s]{0,1}(<|&lt;)\/[a-zA-Z-]+((>|&gt;){0,1})))/g
      let data = item.match(regexp)
      let value = data && data.length ? data[0] : ''
      let regexp_ = /<span(\S|\s)*?<\/span>/g
      let data_ = value.match(regexp_)
      if (data_ && data_.length) {
        let res = data_[0].match(regexp)
        value = res && res.length ? res[0] : ''
        return this.data[value] ? this.data[value] : value
      } else {
        return this.data[value] ? this.data[value] : value
      }
    },
    getIsChildren(item) {
      let regular = /data-tag=[\"|'](.*?)[\"|']/gi;
      let quotes = /["|'](.*)["|']/;
      let data = item.match(regular)
      if (data && data.length) {
        let res = data[0].match(quotes)
        data = res && res.length ? res[1] : ""
        if (data) {
          const dataTag = data.split('.')[0]
          if (dataTag && dataTag !== 'headTable' && dataTag !== 'null') return true
        }
      }
    },
    getJsBarcode(value, id, width, height) {
      if (!value) return
      JsBarcode(id, value, {
        width: width ? width : 4,
        height: height ? height : 80,
        displayValue: false
      });
    },
    getJsQrcode(value, id, width, height) {
      if (!value) return
      let qrcode = new QRCode(document.getElementById(id), {
        width: width ? width : 265,
        height: height ? height : 265, // 高度
        text: value, // 二维码内容
        correctLevel: QRCode.CorrectLevel.H //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
      })
    },
    replaceAll(data, replace, value) {
      const lenr = replace.length
      const len = data.length
      let newData = ''
      let i = 0
      for (i; i < len; i++) {
        let k = 0
        let string = ''
        for (k; k < lenr; k++) {
          var n = i + k
          string += data[n]
        }
        if (string === replace) {
          newData += value
          i = i + lenr
        }
        newData += data[i]
      }
      return newData
    },
    word() {
      let print = this.$refs.tsPrint.innerHTML
      const blob = new Blob([print], {
        type: ''
      })
      const name = this.fullName ? `${this.fullName}.doc` : '下载文档.doc'
      this.downloadFile(blob, name)
    },
    downloadFile(data, name, type) {
      let blob = new Blob([data], {
        type: type || ''
      })
      let downloadElement = document.createElement('a')
      let href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = name
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    },
    print() {
      let print = this.$refs.tsPrint.innerHTML
      let iframe = document.createElement('IFRAME');
      document.body.appendChild(iframe);
      let doc = iframe.contentWindow.document
      doc.write(print);
      doc.close();
      iframe.contentWindow.focus();
      iframe.contentWindow.addEventListener('load', function () {
        let oldTitle = document.title;
        document.title = "JNPF快速开发平台";
        iframe.contentWindow.print();
        document.title = oldTitle;
        document.body.removeChild(iframe);
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.print-content {
  background: white;
  padding: 40px 30px;
  margin: 0 auto;
  border-radius: 4px;
  width: 776px;
  height: 100%;
  overflow: auto;
}
</style>
