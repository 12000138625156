<template>
  <div>
    <template v-if="formConf.popupType==='general'">
      <el-dialog :title=formTitle :close-on-click-modal="false"
                 :visible.sync="visible" class="SNOWS-dialog SNOWS-dialog_center" lock-scroll
                 :width="formConf.generalWidth">
        <parser :form-conf="formConf" @submit="submitForm" :key="key" ref="dynamicForm"
                v-if="!loading"/>
        <span slot="footer" class="dialog-footer">
          <template v-if="formConf.hasPrintBtn && formConf.printId && dataForm.id && false">
            <el-button type="primary" @click="print">
              {{ formConf.printButtonText || '打 印' }}
            </el-button>
          </template>
          <el-button @click="visible = false">{{ formConf.cancelButtonText || '取 消' }}</el-button>
          <el-button type="primary" @click="dataFormSubmit()" :loading="btnLoading" v-if="!formConf.disabled">
            {{ formConf.confirmButtonText || '确 定' }}</el-button>
        </span>
      </el-dialog>
    </template>
    <template v-if="formConf.popupType==='fullScreen'">
      <transition name="el-zoom-in-center">
        <div class="SNOWS-preview-main">
          <div class="SNOWS-common-page-header">
            <el-page-header @back="goBack" :content=formTitle />
            <div class="options">
              <template v-if="formConf.hasPrintBtn && formConf.printId && dataForm.id && false">
                <el-button type="primary" @click="print">
                  {{ formConf.printButtonText || '打 印' }}
                </el-button>
              </template>
              <el-button type="primary" @click="dataFormSubmit()" :loading="btnLoading" v-if="!formConf.disabled">
                {{ formConf.confirmButtonText || '确 定' }}
              </el-button>
              <el-button @click="goBack">{{ formConf.cancelButtonText || '取 消' }}</el-button>
            </div>
          </div>
          <div class="dynamic-form-main" style="margin: 0 auto;width: calc(100% - 20px)">
            <parser :form-conf="formConf" @submit="submitForm" :key="key" ref="dynamicForm"
                    v-if="!loading"/>
          </div>
        </div>
      </transition>
    </template>
    <template v-if="formConf.popupType==='drawer'">
      <el-drawer :title=formTitle :visible.sync="visible"
                 :wrapperClosable="false" :size='formConf.drawerWidth' append-to-body
                 class="SNOWS-common-drawer">
        <div class="SNOWS-flex-main">
          <div class="dynamicForm">
            <parser :form-conf="formConf" @submit="submitForm" :key="key" ref="dynamicForm"
                    v-if="!loading"/>
          </div>
          <div class="drawer-footer">
            <template v-if="formConf.hasPrintBtn && formConf.printId && dataForm.id && false">
              <el-button type="primary" @click="print">
                {{ formConf.printButtonText || '打 印' }}
              </el-button>
            </template>
            <el-button @click="visible = false">{{ formConf.cancelButtonText || '取 消' }}</el-button>
            <el-button type="primary" @click="dataFormSubmit()" :loading="btnLoading" v-if="!formConf.disabled">
              {{ formConf.confirmButtonText || '确 定' }}
            </el-button>
          </div>
        </div>
      </el-drawer>
    </template>
    <print-browse :visible.sync="printBrowseVisible" :id="formConf.printId" :formId="dataForm.id"/>
  </div>
</template>

<script>
import {createModel, updateModel, getModelInfo} from '@/api/onlineDev/visualDev'
import Parser from '@/components/Generator/parser/Parser'
import PrintBrowse from '@/components/PrintBrowse'
import {deepClone} from '@/util'
import {mapGetters} from "vuex";

export default {
  components: {Parser, PrintBrowse},
  data() {
    return {
      visible: false,
      key: +new Date(),
      formConf: {},
      dataForm: {
        id: '',
        data: ''
      },
      modelId: '',
      formData: {},
      btnLoading: false,
      loading: true,
      isPreview: false,
      useFormPermission: false,
      printBrowseVisible: false,
      formOperates: [],
      formTitle:''
    }
  },
  computed:{
    ...mapGetters(["userData"])
  },
  methods: {
    goBack() {
      this.$emit('refreshDataList')
    },
    print() {
      if (this.isPreview) return this.$message({message: '功能预览不支持打印', type: 'warning'})
      this.printBrowseVisible = true
    },
    init(formConf, modelId, id, isPreview, useFormPermission) {
      this.formConf = deepClone(formConf)
      this.modelId = modelId
      this.isPreview = isPreview
      this.useFormPermission = useFormPermission
      this.dataForm.id = id || ''
      this.getFormOperates()
      this.loading = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          let queryJson = {
            id: this.dataForm.id
          }
          createModel(modelId, {
            id: this.dataForm.id,
            type: 'page',
            queryJson: JSON.stringify(queryJson),
            currentPage: 1,
            pageSize: 20
          }).then(res => {
            this.dataForm.data = res.data.data.list[0];
            if (!this.dataForm.data) return
            this.formConf.fields.forEach((item) => {
              if (item.__config__.jnpfKey == 'cascader') {
                this.dataForm.data[item.__vModel__] = JSON.parse(this.dataForm.data[item.__vModel__])
              }
              if (item.__config__.jnpfKey == 'checkbox') {
                this.dataForm.data[item.__vModel__] = JSON.parse(this.dataForm.data[item.__vModel__])
              }
              if (item.__config__.jnpfKey == 'switch') {
                this.dataForm.data[item.__vModel__] = Number(this.dataForm.data[item.__vModel__])
              }
              if (item.__config__.jnpfKey == 'uploadFz') {
                this.dataForm.data[item.__vModel__] = JSON.parse(this.dataForm.data[item.__vModel__])
              }
              if (item.__config__.jnpfKey == 'slider') {
                this.dataForm.data[item.__vModel__] = Number(this.dataForm.data[item.__vModel__])
              }
              if (item.__config__.jnpfKey == 'comSelect') {
                this.dataForm.data[item.__vModel__] = JSON.parse(this.dataForm.data[item.__vModel__])
              }
              if (item.__config__.jnpfKey == 'uploadImg') {
                this.dataForm.data[item.__vModel__] = JSON.parse(this.dataForm.data[item.__vModel__])
              }
              if (item.__config__.jnpfKey == 'date') {
                this.dataForm.data[item.__vModel__] = new Date(this.dataForm.data[item.__vModel__]).getTime()
              }
              if (item.__config__.jnpfKey == 'address') {
                this.dataForm.data[item.__vModel__] = eval('(' + this.dataForm.data[item.__vModel__] + ')')
              }
              if (item.__config__.jnpfKey == 'select') {
                if (item.multiple == true) {
                  this.dataForm.data[item.__vModel__] = JSON.parse(this.dataForm.data[item.__vModel__])
                }
              }
              if (item.__config__.jnpfKey == 'createUser') {
                let data = this.userData.filter((items) => {
                  return items.id == this.dataForm.data[item.__vModel__]
                })
                this.dataForm.data[item.__vModel__] = data[0].realName + '/' + data[0].account
              }
              if (item.__config__.jnpfKey == 'modifyUser') {
                let data = this.userData.filter((items) => {
                  return items.id == this.dataForm.data[item.__vModel__]
                })
                this.dataForm.data[item.__vModel__] = data[0].realName + '/' + data[0].account
              }
              if (item.__vModel__) {
                if (item.__vModel__.startsWith("tableField")) {
                  item.__config__.children.forEach((items) => {
                    if (items.__config__.jnpfKey == 'cascader') {
                      this.dataForm.data[item.__vModel__].forEach((crs) => {
                        crs[items.__vModel__] = JSON.parse(crs[items.__vModel__]);
                      })
                    }
                    if (items.__config__.jnpfKey == 'switch') {
                      this.dataForm.data[item.__vModel__].forEach((crs) => {
                        crs[items.__vModel__] = Number(crs[items.__vModel__]);
                      })
                    }
                    if (items.__config__.jnpfKey == 'uploadFz') {
                      this.dataForm.data[item.__vModel__].forEach((crs) => {
                        crs[items.__vModel__] = JSON.parse(crs[items.__vModel__]);
                      })
                    }
                    if (items.__config__.jnpfKey == 'uploadImg') {
                      this.dataForm.data[item.__vModel__].forEach((crs) => {
                        crs[items.__vModel__] = JSON.parse(crs[items.__vModel__]);
                      })
                    }
                    if (items.__config__.jnpfKey == 'comSelect') {
                      this.dataForm.data[item.__vModel__].forEach((crs) => {
                        crs[items.__vModel__] = JSON.parse(crs[items.__vModel__]);
                      })
                    }
                    if (items.__config__.jnpfKey == 'select') {
                      if (items.multiple == true) {
                        this.dataForm.data[item.__vModel__].forEach((crs) => {
                          crs[items.__vModel__] = JSON.parse(crs[items.__vModel__]);
                        })
                      }
                    }
                  })
                }
              }
            })
            this.formData = {...this.dataForm.data, id: this.dataForm.id}
            this.fillFormData(this.formConf, this.formData)
            this.$nextTick(() => {
              this.visible = true
              this.loading = false
            })
          })
        } else {
          this.dataForm.data = {};
          this.formConf.fields.forEach((item) => {
            if (item.__config__.jnpfKey == 'date' && item.default == true) {
              this.dataForm.data[item.__vModel__] = Date.parse(new Date());
            }
            if (item.__config__.jnpfKey == 'time' && item.default == true) {
              const DateTime = new Date();
              const hour = DateTime.getHours() > 9 ? DateTime.getHours() : '0' + DateTime.getHours();
              const minute = DateTime.getMinutes() > 9 ? DateTime.getMinutes() : '0' + DateTime.getMinutes();
              const second = DateTime.getSeconds() > 9 ? DateTime.getSeconds() : '0' + DateTime.getSeconds();
              this.dataForm.data[item.__vModel__] = hour + ':' + minute + ':' + second
            }
            if (item.__config__.jnpfKey == 'userSelect' && item.default == true) {
              this.dataForm.data[item.__vModel__] = this.$store.getters.userInfo.user_id
            }
          })
          this.formData = {...this.dataForm.data, id: this.dataForm.id}
          this.fillFormData(this.formConf, this.formData)
          this.visible = true
          this.loading = false
        }
        this.key = +new Date()
      })
      if (this.dataForm.id != '' && this.formConf.disabled == false) {
        this.formTitle = "编辑"
      } else if (this.dataForm.id != '' && this.formConf.disabled == true) {
        this.formTitle = "查看"
      } else if (this.dataForm.id == '' && this.formConf.disabled == false) {
        this.formTitle = "新增"
      }
    },
    getFormOperates() {
      if (this.isPreview || !this.useFormPermission) return
      const permissionList = this.$store.getters.permissionList
      const modelId = this.$route.meta.modelId
      const list = permissionList.filter(o => o.modelId === modelId)
      this.formOperates = list[0] && list[0].form ? list[0].form : []
    },
    fillFormData(form, data) {
      const loop = (list, parent) => {
        for (let i = 0; i < list.length; i++) {
          let item = list[i]
          if (item.__vModel__) {
            const val = data.hasOwnProperty(item.__vModel__) ? data[item.__vModel__] : item.__config__.defaultValue
            if (!item.__config__.isSubTable) item.__config__.defaultValue = val
            if (!this.isPreview && this.useFormPermission) {
              let id = item.__config__.isSubTable ? parent.__vModel__ + '-' + item.__vModel__ : item.__vModel__
              let noShow = true
              if (this.formOperates && this.formOperates.length) {
                noShow = !this.formOperates.some(o => o.enCode === id)
              }
              noShow = item.__config__.noShow ? item.__config__.noShow : noShow
              this.$set(item.__config__, 'noShow', noShow)
            }
          }
          if (item.__config__ && item.__config__.children && Array.isArray(item.__config__.children)) {
            loop(item.__config__.children, item)
          }
        }
      }
      loop(form.fields)
      form.formData = data
    },
    submitForm(data, callback) {
      if (!data) return
      this.btnLoading = true
      const formData = {...this.formData, ...data}
      this.dataForm.data = JSON.stringify(formData)
      // const formMethod = this.dataForm.id ? updateModel : createModel
      this.dataForm.type = this.dataForm.id ? 'update' : 'add';
      createModel(this.modelId, this.dataForm).then(res => {
        this.$message({
          message: res.data.msg,
          type: 'success',
          onClose: () => {
            if (callback && typeof callback === "function") callback()
            this.visible = false
            this.btnLoading = false
            this.$emit('refreshDataList', true)
          }
        })
      }).catch(() => {
        this.btnLoading = false
      })
    },
    dataFormSubmit() {
      if (this.isPreview) return this.$message({message: '功能预览不支持数据保存', type: 'warning'})
      this.$refs.dynamicForm && this.$refs.dynamicForm.submitForm()
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-input.is-disabled .el-input__inner {
  color: #636363;
}
::v-deep .el-textarea.is-disabled .el-textarea__inner {
  color: #636363;
}
</style>
